import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import isFunction from "lodash";

import request from "API";

import {
  calculatePaymentFailure,
  calculatePaymentRequest,
  calculatePaymentSuccess,
  paymentFailure,
  paymentRequest,
  paymentSuccess,
  servicesAllFailure,
  servicesAllRequest,
  servicesAllSuccess,
  servicesFailure,
  servicesRequest,
  servicesSuccess,
  userPaymentMethodStateFailure,
  userPaymentMethodStatePendingFalse,
  userPaymentMethodStateRequest,
  userPaymentMethodStateSuccess,
} from "store/services/reducers";

import delay from "utils/delay";

import { IPayloadAction } from "../rootInterface";

import { TPaymentCalculateOptions, TPaymentData, TPaymentOptions, YookassaPaymentStatus } from "./types";

function* getServices() {
  try {
    const response: AxiosResponse<TPaymentData> = yield call(request.get, "/services/");
    yield put(servicesSuccess(response.data));
  } catch (e) {
    yield put(servicesFailure(e));
  }
}

function* getAllServices() {
  try {
    const response: AxiosResponse<TPaymentData> = yield call(request.get, "/services/all/");
    yield put(servicesAllSuccess(response.data));
  } catch (e) {
    yield put(servicesAllFailure(e));
  }
}

function* payment(action: IPayloadAction<{data:TPaymentOptions, callOnSuccess: () => void}>) {
  const { data, callOnSuccess } = action.payload;
  try {
    const response: AxiosResponse<TPaymentData> = yield call(request.post, "/user/payment/", data);
    yield put(paymentSuccess(response.data));

    if (response.data.payment_url) {
      window.location.replace(response.data.payment_url);
    }

    if (isFunction(callOnSuccess) && response.data.status === YookassaPaymentStatus.succeeded) {
      callOnSuccess();
    }

    if (
      response.data.status === YookassaPaymentStatus.pending
      || response.data.status === YookassaPaymentStatus.waitingForCapture
    ) {
      yield put(userPaymentMethodStateRequest({ id: response.data.id }));
    }
  } catch (e) {
    yield put(paymentFailure(e));
  }
}

function* calculatePayment(action: IPayloadAction<TPaymentCalculateOptions>) {
  try {
    const response: AxiosResponse = yield call(request.post, "/user/payment/calculate/", action.payload);
    yield put(calculatePaymentSuccess(response.data));
  } catch (e) {
    yield put(calculatePaymentFailure(e));
  }
}

function* getUserPaymentMethodState(action: IPayloadAction<{ id: string }>) {
  try {
    const response: AxiosResponse<TPaymentData> =
      yield call(request.get, `/user/payment/${action.payload.id}/state/`);
    yield put(userPaymentMethodStateSuccess(response.data));

    if (
      response.data.status === YookassaPaymentStatus.pending
      || response.data.status === YookassaPaymentStatus.waitingForCapture
    ) {
      yield delay(5000);
      yield put(userPaymentMethodStateRequest({ id: action.payload.id }));
    } else {
      yield put(userPaymentMethodStatePendingFalse());
    }
  } catch (e) {
    yield put(userPaymentMethodStateFailure(e));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(servicesRequest.type, getServices),
    takeLatest(servicesAllRequest.type, getAllServices),
    takeLatest(calculatePaymentRequest.type, calculatePayment),
    takeLatest(paymentRequest.type, payment),
    takeLatest(userPaymentMethodStateRequest.type, getUserPaymentMethodState)
  ]);
}

export default Saga;
