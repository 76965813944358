import { colors } from "config/constants";

{/* eslint-disable max-len */}
const OtherIcon = (): JSX.Element => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5058 2.84151L10.404 -0.260278C10.2942 -0.297778 10.1469 -0.316528 9.99958 -0.316528C9.85226 -0.316528 9.70494 -0.297778 9.59512 -0.260278L0.493332 2.84151C0.27101 2.91651 0.0888672 3.17365 0.0888672 3.40936V16.3308C0.0888672 16.5665 0.241546 16.8772 0.426367 17.0245L9.6594 24.2192C9.75315 24.2915 9.87369 24.329 9.9969 24.329C10.1201 24.329 10.2433 24.2915 10.3344 24.2192L19.5674 17.0245C19.7523 16.8799 19.9049 16.5692 19.9049 16.3308V3.40936C19.9103 3.17365 19.7282 2.91919 19.5058 2.84151ZM17.9817 15.8165L9.99958 22.0361L2.01744 15.8165V4.36294L9.99958 1.64151L17.9817 4.36294V15.8165ZM7.11208 10.4326C7.03172 10.3228 6.90315 10.2558 6.76387 10.2558H5.2853C5.11119 10.2558 5.0094 10.454 5.11119 10.596L8.4969 15.2567C8.53694 15.3115 8.58934 15.3561 8.64984 15.3868C8.71035 15.4175 8.77725 15.4335 8.84512 15.4335C8.91298 15.4335 8.97988 15.4175 9.04039 15.3868C9.1009 15.3561 9.1533 15.3115 9.19333 15.2567L14.888 7.41651C14.9898 7.27454 14.888 7.07633 14.7139 7.07633H13.2353C13.0987 7.07633 12.9674 7.14329 12.8871 7.25311L8.84512 12.8192L7.11208 10.4326Z"
      fill={colors.main}
    />
  </svg>
);

export default OtherIcon;
