import { FC } from "react";

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const FluentShieldOk: FC<IIconsProps> = ({ className, onClick }) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <g clipPath="url(#clip0_9489_4331)">
      <path d="M4.81979 0.954666C4.86827 0.903542 4.93284 0.875 5 0.875C5.06716 0.875 5.13172 0.903542 5.18021 0.954666C5.75479 1.56036 6.57271 1.88899 7.27125 2.06384C7.61688 2.15001 7.92521 2.19676 8.14667 2.22197C8.26126 2.2349 8.37617 2.24422 8.49125 2.24993H8.49583C8.56381 2.25172 8.62845 2.2827 8.67595 2.33622C8.72344 2.38975 8.75002 2.46159 8.75 2.53639V4.77079C8.75 6.94721 7.69583 9.12867 5.07771 10.0286C5.02711 10.046 4.97289 10.046 4.92229 10.0286C3.62271 9.58174 2.69979 8.81585 2.10354 7.8703C1.50917 6.92681 1.25 5.82199 1.25 4.7135V2.53639C1.24998 2.46159 1.27656 2.38975 1.32405 2.33622C1.37155 2.2827 1.43619 2.25172 1.50417 2.24993H1.50875L1.52542 2.24924C1.63493 2.24335 1.74427 2.23418 1.85333 2.22174C2.14774 2.18826 2.44007 2.13546 2.72875 2.06361C3.42708 1.88899 4.245 1.56036 4.81979 0.954436V0.954666ZM6.79437 4.34774C6.81983 4.32197 6.84036 4.29087 6.85477 4.25627C6.86917 4.22167 6.87717 4.18426 6.87828 4.14623C6.8794 4.10819 6.87362 4.07029 6.86127 4.03474C6.84892 3.99919 6.83026 3.9667 6.80637 3.93918C6.78248 3.91165 6.75384 3.88964 6.72213 3.87442C6.69042 3.8592 6.65627 3.85109 6.62168 3.85055C6.58709 3.85001 6.55274 3.85706 6.52066 3.87129C6.48857 3.88551 6.45938 3.90663 6.43479 3.93341L4.32708 6.14649L3.56958 5.313C3.52074 5.25927 3.45449 5.22909 3.38542 5.22909C3.31634 5.22909 3.25009 5.25927 3.20125 5.313C3.15241 5.36673 3.12497 5.4396 3.12497 5.51559C3.12497 5.59157 3.15241 5.66445 3.20125 5.71817L4.13875 6.74944C4.23875 6.85944 4.40042 6.86173 4.50271 6.75402L6.79437 4.34774Z" fill="#59BD83"/>
    </g>
    <defs>
      <clipPath id="clip0_9489_4331">
        <rect width="10" height="10" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
);

export default FluentShieldOk;
