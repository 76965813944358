import { colors } from "config/constants";

{/* eslint-disable max-len */}
const BitnalogIcon = (): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 14 14" fill="none">
    <path
      d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125ZM7.0875 4.48906C7.91562 4.48906 8.47812 5.01719 8.54062 5.79219C8.54531 5.85781 8.6 5.90781 8.66562 5.90781H9.55156C9.59219 5.90781 9.625 5.875 9.625 5.83437C9.625 4.47969 8.55625 3.53125 7.08281 3.53125C5.36562 3.53125 4.375 4.69062 4.375 6.60625V7.42344C4.375 9.325 5.36562 10.4688 7.08281 10.4688C8.55156 10.4688 9.625 9.55 9.625 8.25937C9.625 8.21875 9.59219 8.18594 9.55156 8.18594H8.66406C8.59844 8.18594 8.54531 8.23594 8.53906 8.3C8.47344 9.02031 7.9125 9.51562 7.08594 9.51562C6.06563 9.51562 5.49062 8.76719 5.49062 7.42813V6.60625C5.49219 5.24687 6.06875 4.48906 7.0875 4.48906Z"
      fill={colors.gray8}
    />
  </svg>
);

export default BitnalogIcon;
