import { colors } from "config/constants";

{/* eslint-disable max-len */}
const CompassIcon = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8110_1023)">
      <path d="M12 0C5.37321 0 0 5.37321 0 12C0 18.6268 5.37321 24 12 24C18.6268 24 24 18.6268 24 12C24 5.37321 18.6268 0 12 0ZM12 21.9643C6.49821 21.9643 2.03571 17.5018 2.03571 12C2.03571 6.49821 6.49821 2.03571 12 2.03571C17.5018 2.03571 21.9643 6.49821 21.9643 12C21.9643 17.5018 17.5018 21.9643 12 21.9643ZM17.3143 6.21161C17.2094 6.17062 17.0974 6.1507 16.9848 6.153C16.8722 6.15529 16.7612 6.17976 16.658 6.225L9.39911 9.40179L6.225 16.6607C6.12857 16.8804 6.12857 17.1295 6.225 17.3491C6.31607 17.558 6.48482 17.7214 6.69911 17.8045C6.80089 17.8446 6.90536 17.8634 7.0125 17.8634C7.13036 17.8634 7.24554 17.8393 7.35536 17.7911L14.6143 14.6143L17.7884 7.35536C17.8347 7.24942 17.8591 7.13522 17.8603 7.01961C17.8614 6.904 17.8391 6.78935 17.7948 6.68255C17.7506 6.57574 17.6852 6.47898 17.6026 6.39805C17.52 6.31713 17.422 6.25371 17.3143 6.21161ZM13.7357 12.6L14.4375 13.3018L13.3018 14.4375L12.6 13.7357L8.46429 15.5464L10.2723 11.4107L9.58929 10.725L10.725 9.58929L11.408 10.2723L15.5464 8.46429L13.7357 12.6ZM10.725 9.58929L9.58929 10.725L10.2723 11.4107L12.6 13.7357L13.3018 14.4375L14.4375 13.3018L13.7357 12.6L11.4107 10.2723L10.725 9.58929Z" fill={colors.main}/>
    </g>
    <defs>
      <clipPath id="clip0_8110_1023">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default CompassIcon;
