import { FC, MouseEvent } from 'react';
import { useHistory, useParams } from "react-router-dom";
import cn from "classnames";

import { getAMLSinglePath } from "config/paths";

import FormedRiskIcon from "components/FormedRiskIcon";

export type TAMLPageWrapperWalletsItemProps = { 
  id: string,
  name: string,
  source: string,
  risk: number,
  transactionsCount?: number,
  icon: string
}
const AMLPageWrapperWalletsItem: FC<TAMLPageWrapperWalletsItemProps> = ({ 
  id, name, source, risk, transactionsCount, icon
}) => {
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (id !== params.id) {
      history.push(getAMLSinglePath(id));
    }
  };

  return (
    <a
      className={cn("aml-item", { active: id === params.id })}
      key={id}
      onClick={onClick}
    >
      <img src={icon} alt="aml-icon" className="aml-item-logo" />
      <div className="aml-item__naming">
        <h3 className="aml-item__name">
          {name}
        </h3>
        {source && <span className="aml-item__subname">{source}</span>}
      </div>
      <div className="aml-item__data">
        <div className="aml-item__risk">Risk: {risk}% <FormedRiskIcon risk={risk}/></div>
        {transactionsCount && <div className="aml-item__transactions">New transactions ({transactionsCount})</div>}
      </div>
    </a>
  ); 
};

export default AMLPageWrapperWalletsItem;
