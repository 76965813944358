import { FC } from 'react';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { maxMobileMediaWidth } from "config/constants";

import { AML_WALLETS } from 'components/AMLPageWrapper/constants';
import List from "components/List";
import BNSpinner from "components/BNSpinner";

import AMLPageWrapperWalletsItem from "../AMLPageWrapperWalletsItem";

const AMLPageWrapperWallets: FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });

  const getList = () => {
    if (!!AML_WALLETS?.length) {
      return (
        <>
          {AML_WALLETS?.map(item => (
            <AMLPageWrapperWalletsItem
              key={item.id}
              id={item.id}
              name={item.name}
              source={item.source}
              risk={item.risk}
              transactionsCount={item.transactions.length}
              icon={item.icon}
            />
          ))}
        </>
      );
    } else return <h3 className="empty-text">{t("assetsList.emptyText")}</h3>;
  };

  return (
    <div className="aml-list_wrapper">
      <div className="aml-list">
        <List
          loading={false}
          component={getList()}
          preloader={<BNSpinner />}
          emptyText={<h3 className="empty-text">{t("assetsList.emptyText2")}</h3>}
          data={AML_WALLETS}
        />
      </div>
      {!isMobile && <div className="aml-list_line" />}
    </div>
  );
};

export default AMLPageWrapperWallets;