{/* eslint-disable max-len */}
const ExitIcon = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5361 17.8941H19.6531C19.5245 17.8941 19.404 17.9503 19.3236 18.0494C19.1361 18.2771 18.9352 18.4968 18.7236 18.7057C17.8581 19.572 16.833 20.2624 15.7049 20.7387C14.5361 21.2324 13.2799 21.4856 12.0111 21.4834C10.7281 21.4834 9.48521 21.2316 8.31735 20.7387C7.18921 20.2624 6.16407 19.572 5.2986 18.7057C4.43159 17.8423 3.74025 16.8189 3.26289 15.6923C2.76735 14.5245 2.51824 13.2843 2.51824 12.0012C2.51824 10.7182 2.77003 9.47802 3.26289 8.31016C3.73967 7.18249 4.42539 6.16731 5.2986 5.29677C6.17182 4.42624 7.18699 3.74052 8.31735 3.26374C9.48521 2.77088 10.7281 2.51909 12.0111 2.51909C13.2941 2.51909 14.537 2.7682 15.7049 3.26374C16.8352 3.74052 17.8504 4.42624 18.7236 5.29677C18.9352 5.50838 19.1334 5.72802 19.3236 5.95302C19.404 6.05213 19.5272 6.10838 19.6531 6.10838H21.5361C21.7049 6.10838 21.8093 5.92088 21.7156 5.77891C19.6611 2.58606 16.0665 0.472664 11.9816 0.483378C5.56378 0.499449 0.418244 5.70927 0.482529 12.1191C0.546815 18.4271 5.68432 23.5191 12.0111 23.5191C16.0852 23.5191 19.6638 21.4084 21.7156 18.2236C21.8066 18.0816 21.7049 17.8941 21.5361 17.8941ZM23.9174 11.8325L20.1165 8.83249C19.9745 8.71999 19.7682 8.82177 19.7682 9.00124V11.037H11.3575C11.2397 11.037 11.1432 11.1334 11.1432 11.2512V12.7512C11.1432 12.8691 11.2397 12.9655 11.3575 12.9655H19.7682V15.0012C19.7682 15.1807 19.9772 15.2825 20.1165 15.17L23.9174 12.17C23.943 12.1499 23.9637 12.1243 23.9779 12.0951C23.9922 12.0659 23.9996 12.0338 23.9996 12.0012C23.9996 11.9687 23.9922 11.9366 23.9779 11.9074C23.9637 11.8781 23.943 11.8525 23.9174 11.8325Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);

export default ExitIcon;
