import { FC } from 'react';
import cn from "classnames";

import FluentShieldOk from 'components/Icons/FluentShieldOk';
import FluentShieldWarning from 'components/Icons/FluentShieldWarning';
import FluentShieldError from 'components/Icons/FluentShieldError';

export type TFormedRiskIconProps = {
  risk: number;
  className?: string;
};

const FormedRiskIcon: FC<TFormedRiskIconProps> = ({ risk, className }) => {
  if (risk > 80) {
    return <FluentShieldOk className={cn("formed-risk-icon", className)}/>;
  } else if (risk > 40) {
    return <FluentShieldWarning className={cn("formed-risk-icon", className)}/>;
  } else {
    return <FluentShieldError className={cn("formed-risk-icon", className)}/>;
  }
};

export default FormedRiskIcon;