import { FC } from 'react';
import { useDispatch } from "react-redux";

import Button from "components/Buttons/Button";
import EqualizerIcon from 'components/Icons/EqualizerIcon';
import ClarityArchiveLineIcon from 'components/Icons/ClarityArchiveLineIcon';

import { EModals } from "store/modals/types";
import { openModal } from "store/modals/reducers";
import { showModal as showModalAction } from "store/assets/reducers";

import AssetsModal from "../../../AssetsPageWrapper/components/AssetsModal";

import AMLPageWrapperWallets from '../AMLPageWrapperWallets';

const AMLPageWrapperSidebar: FC = () => {
  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(openModal(EModals.amlAddWallet));
    dispatch(showModalAction(true));
  };

  return (
    <aside className="aml-sidebar">
      <AMLPageWrapperWallets/>
      <Button
        title={"Add Wallet"}
        onClick={showModal}
        className="aml-btn"
      />
      <AssetsModal />
      <div className="aml-sidebar__additional">
        <div className="aml-sidebar__manual-checks">
          <EqualizerIcon/>
          <span className="title">Manual Checks</span>
          <span className="count">7</span>
        </div>
        <div className="aml-sidebar__archive">
          <ClarityArchiveLineIcon/>
          <span className="title">Archive</span>
          <span className="count">7</span>
        </div>
      </div>
    </aside>
  );
};

export default AMLPageWrapperSidebar;