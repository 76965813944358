import { FC } from "react";

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const FluentShieldWarning: FC<IIconsProps> = ({ className, onClick }) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <g clipPath="url(#clip0_9489_4244)">
      <path d="M8.4375 2.24998C7.32792 2.24998 6.24667 1.81778 5.1875 0.943749C5.13341 0.899123 5.06762 0.875 5 0.875C4.93238 0.875 4.86659 0.899123 4.8125 0.943749C3.75333 1.81778 2.67208 2.24998 1.5625 2.24998C1.47962 2.24998 1.40013 2.2862 1.34153 2.35066C1.28292 2.41513 1.25 2.50256 1.25 2.59373V4.99995C1.25 7.29205 2.4825 8.9764 4.88542 10.0177C4.95905 10.0496 5.04095 10.0496 5.11458 10.0177C7.5175 8.9764 8.75 7.29159 8.75 4.99995V2.59373C8.75 2.50256 8.71708 2.41513 8.65847 2.35066C8.59987 2.2862 8.52038 2.24998 8.4375 2.24998ZM4.69042 3.45539C4.70071 3.37311 4.73773 3.29772 4.79464 3.24314C4.85155 3.18856 4.9245 3.1585 5 3.1585C5.0755 3.1585 5.14845 3.18856 5.20536 3.24314C5.26227 3.29772 5.29929 3.37311 5.30958 3.45539L5.3125 3.50214V6.48127L5.30958 6.52802C5.29929 6.61029 5.26227 6.68569 5.20536 6.74026C5.14845 6.79484 5.0755 6.82491 5 6.82491C4.9245 6.82491 4.85155 6.79484 4.79464 6.74026C4.73773 6.68569 4.70071 6.61029 4.69042 6.52802L4.6875 6.48127V3.50214L4.69042 3.45539ZM5 8.20825C4.88949 8.20825 4.78351 8.15996 4.70537 8.07401C4.62723 7.98805 4.58333 7.87147 4.58333 7.74992C4.58333 7.62836 4.62723 7.51179 4.70537 7.42583C4.78351 7.33988 4.88949 7.29159 5 7.29159C5.11051 7.29159 5.21649 7.33988 5.29463 7.42583C5.37277 7.51179 5.41667 7.62836 5.41667 7.74992C5.41667 7.87147 5.37277 7.98805 5.29463 8.07401C5.21649 8.15996 5.11051 8.20825 5 8.20825Z" fill="#FFCF25"/>
    </g>
    <defs>
      <clipPath id="clip0_9489_4244">
        <rect width="10" height="10" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
);

export default FluentShieldWarning;
