export const AML_WALLETS = [
  {
    id: "1",
    name: "Source name",
    source: "BTC",
    risk: 100,
    lastCheck: "01.10.2022",
    icon: "https://qa0.bittax.ru/backend/media/stock/2022/06/07/9b80267ce94a43e6a3d881107a0a232a.svg",
    sources: {
      trusted: 45.9,
      suspicious: 30.4,
      danger: 23.7,
    },
    transactions: [
      {
        id: "1",
        date: "11.12.2021",
        time: "00:00:00",
        name: "Source name",
        subname: "Full AML check",
        risk: 50,
        sources: {
          trusted: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          suspicious: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          danger: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
        },
      }
    ]
  },
  {
    id: "2",
    name: "Source name",
    source: "BTC",
    risk: 100,
    lastCheck: "01.10.2022",
    icon: "https://qa0.bittax.ru/backend/media/stock/2022/06/07/9b80267ce94a43e6a3d881107a0a232a.svg",
    sources: {
      trusted: 45.9,
      suspicious: 30.4,
      danger: 23.7,
    },
    transactions: [
      {
        id: "1",
        date: "11.12.2021",
        time: "00:00:00",
        name: "Source name",
        subname: "Full AML check",
        risk: 50,
        sources: {
          trusted: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          suspicious: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          danger: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
        },
      }
    ]
  },
  {
    id: "3",
    name: "Source name",
    source: "BTC",
    risk: 100,
    lastCheck: "01.10.2022",
    icon: "https://qa0.bittax.ru/backend/media/stock/2022/06/07/9b80267ce94a43e6a3d881107a0a232a.svg",
    sources: {
      trusted: 45.9,
      suspicious: 30.4,
      danger: 23.7,
    },
    transactions: [
      {
        id: "1",
        date: "11.12.2021",
        time: "00:00:00",
        name: "Source name",
        subname: "Full AML check",
        risk: 50,
        sources: {
          trusted: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          suspicious: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          danger: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
        },
      }
    ]
  },
  {
    id: "4",
    name: "Source name",
    source: "BTC",
    risk: 100,
    lastCheck: "01.10.2022",
    icon: "https://qa0.bittax.ru/backend/media/stock/2022/06/07/9b80267ce94a43e6a3d881107a0a232a.svg",
    sources: {
      trusted: 45.9,
      suspicious: 30.4,
      danger: 23.7,
    },
    transactions: [
      {
        id: "1",
        date: "11.12.2021",
        time: "00:00:00",
        name: "Source name",
        subname: "Full AML check",
        risk: 50,
        sources: {
          trusted: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          suspicious: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          danger: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
        },
      }
    ]
  },
  {
    id: "5",
    name: "Source name",
    source: "BTC",
    risk: 100,
    lastCheck: "01.10.2022",
    icon: "https://qa0.bittax.ru/backend/media/stock/2022/06/07/9b80267ce94a43e6a3d881107a0a232a.svg",
    sources: {
      trusted: 45.9,
      suspicious: 30.4,
      danger: 23.7,
    },
    transactions: [
      {
        id: "1",
        date: "11.12.2021",
        time: "00:00:00",
        name: "Source name",
        subname: "Full AML check",
        risk: 50,
        sources: {
          trusted: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          suspicious: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          danger: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
        },
      }
    ]
  },
  {
    id: "6",
    name: "Source name",
    source: "BTC",
    risk: 100,
    lastCheck: "01.10.2022",
    icon: "https://qa0.bittax.ru/backend/media/stock/2022/06/07/9b80267ce94a43e6a3d881107a0a232a.svg",
    sources: {
      trusted: 45.9,
      suspicious: 30.4,
      danger: 23.7,
    },
    transactions: [
      {
        id: "1",
        date: "11.12.2021",
        time: "00:00:00",
        name: "Source name",
        subname: "Full AML check",
        risk: 50,
        sources: {
          trusted: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          suspicious: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          danger: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
        },
      }
    ]
  },
  {
    id: "7",
    name: "Source name",
    source: "BTC",
    risk: 100,
    lastCheck: "01.10.2022",
    icon: "https://qa0.bittax.ru/backend/media/stock/2022/06/07/9b80267ce94a43e6a3d881107a0a232a.svg",
    sources: {
      trusted: 45.9,
      suspicious: 30.4,
      danger: 23.7,
    },
    transactions: [
      {
        id: "1",
        date: "11.12.2021",
        time: "00:00:00",
        name: "Source name",
        subname: "Full AML check",
        risk: 50,
        sources: {
          trusted: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          suspicious: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          danger: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
        },
      }
    ]
  },
  {
    id: "8",
    name: "Source name",
    source: "BTC",
    risk: 100,
    lastCheck: "01.10.2022",
    icon: "https://qa0.bittax.ru/backend/media/stock/2022/06/07/9b80267ce94a43e6a3d881107a0a232a.svg",
    sources: {
      trusted: 45.9,
      suspicious: 30.4,
      danger: 23.7,
    },
    transactions: [
      {
        id: "1",
        date: "11.12.2021",
        time: "00:00:00",
        name: "Source name",
        subname: "Full AML check",
        risk: 50,
        sources: {
          trusted: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          suspicious: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          danger: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
        },
      }
    ]
  },
  {
    id: "9",
    name: "Source name",
    source: "BTC",
    risk: 100,
    lastCheck: "01.10.2022",
    icon: "https://qa0.bittax.ru/backend/media/stock/2022/06/07/9b80267ce94a43e6a3d881107a0a232a.svg",
    sources: {
      trusted: 45.9,
      suspicious: 30.4,
      danger: 23.7,
    },
    transactions: [
      {
        id: "1",
        date: "11.12.2021",
        time: "00:00:00",
        name: "Source name",
        subname: "Full AML check",
        risk: 50,
        sources: {
          trusted: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          suspicious: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
          danger: [
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 10.0 },
            { source: "Source", risk: 8.0 },
            { source: "Source", risk: 5.9 },
            { source: "Source", risk: 2.0 },
          ],
        },
      }
    ]
  },
];