import { FC } from 'react';
import { useMediaQuery } from "react-responsive";

import { maxMobileMediaWidth } from "config/constants";

import AMLPageWrapperSidebar from './components/AMLPageWrapperSidebar';

const AMLPageWrapper: FC = ({ children }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  
  const hasId = () => {
    const last = location.pathname.split("/").slice(-1)[0];
    if (last === "aml") return false;
    return !!last;
  };

  return (<div className="container">

    {!hasId && <div className="aml-title_wrapper">
      <h1 className="aml-title">
        {!isMobile ? "AML" : "Available accounts for AML Сheck"}
      </h1>
      <p className="aml-count">6 checks left</p>
    </div>}
    <section className="aml">
      {isMobile && !hasId() && <AMLPageWrapperSidebar/>}
      {isMobile && hasId() && <section className="aml-content">{children}</section>}
      {!isMobile && <AMLPageWrapperSidebar />}
      {!isMobile && <section className="aml-content">{children}</section>}
    </section>
  </div>);
};

export default AMLPageWrapper;